<template>
  <div>
    <page-title :icon=icon :heading="$t('generic.lang_AdditionalStatistics')" :subheading="$t('generic.lang_AdditionalStatistics')" show-previous-button></page-title>
    <div class="app-main__inner">
      <CreateAdditionalStatisticsComponent/>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/Layout/Components/PageTitle";
import CreateAdditionalStatisticsComponent
  from "@/components/settings/cashier/additionalstatistics/CreateAdditionalStatisticsComponent";
export default {
  name: "CreateAdditionalStatistics",
  components: {CreateAdditionalStatisticsComponent, PageTitle},
  data: () => ({
    icon: 'pe-7s-tools icon-gradient bg-tempting-azure',
  })
}
</script>

<style scoped>

</style>
