import { render, staticRenderFns } from "./CreateAdditionalStatisticsComponent.vue?vue&type=template&id=4e831b96&scoped=true"
import script from "./CreateAdditionalStatisticsComponent.vue?vue&type=script&lang=js"
export * from "./CreateAdditionalStatisticsComponent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e831b96",
  null
  
)

export default component.exports